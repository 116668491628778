<template>
  <div>
    <div v-if="results.statusCode === 'NOT_FOUND'">
      <div class="check-results-block-container">
        <div class="check-results-main-info">
          <i class="bi-question-lg"></i>
          <h4>{{ $lang.app.application_not_found }}</h4>
          <span>{{ $lang.app.recheck_entered_data }}</span>
        </div>
      </div>
    </div>
    <div v-if="results.statusCode === 'REJECTED' && results.applicationShortDto">
      <div class="check-results-block-container">
        <div class="check-results-username"><h3>{{ results.applicationShortDto.childFio }}</h3></div>
        <div class="check-results-main-info">
          <i class="bi-x-circle"></i>
          <h5>{{ $lang.app.selected_preschool }}</h5>
          <h5>{{ $lang.$translate({ru: results.applicationShortDto.organizationNameRu, kg: results.applicationShortDto.organizationNameKg}) }}</h5>
          <h5>{{ $lang.app.selected_preschool_address }}</h5>
          <h5 class="margin-bottom-35">{{ $lang.$translate({ru: results.applicationShortDto.organizationAddressRu, kg: results.applicationShortDto.organizationAddressKg}) }}</h5>
          <h4>{{ $lang.app.application_rejected }}</h4>
        </div>
        <div v-if="results.applicationShortDto.rejectedReason" class="check-results-notice">
          <i class="check-results-notice-icon bi-exclamation-triangle-fill"></i>
          <div class="check-results-notice-text">
            <h4>{{ $lang.app.attention }}</h4>
            <p>
              <span>{{ results.applicationShortDto.rejectedReason }}</span> <br>
              <span v-if="results.rejectedTime">
                {{ $lang.app.rejected_time_text.replace(':date', $moment(results.rejectedTime).format('DD.MM.YYYY')).replace(':time', $moment(results.rejectedTime).format('HH:mm')) }}
              </span>
            </p>
          </div>
        </div>
        <div class="extra-info">
          <div class="extra-info-content">
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_age }}</div>
              <div class="extra-info-item-value">{{ results.applicationShortDto.age }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.group }}</div>
              <div class="extra-info-item-value">
                {{ $lang.$translate({ru: results.applicationShortDto.preschoolGroupNameRu, kg: results.applicationShortDto.preschoolGroupNameKg}) }}
              </div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_birth_year }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.birthdate).format('YYYY') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.application_created_date }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.createdAt).format('DD.MM.YYYY HH:mm') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.privilege }}</div>
              <div class="extra-info-item-value">{{ applicationPrivilege }}</div>
            </div>
          </div>
        </div>

        <div v-if="results.applicationShortDto.organizationGroupId && organizationGroups" class="organization-quotas-table-wrapper">
          <div><b>{{ $lang.app.your_group }}: {{ $lang.$translate({ru: results.applicationShortDto.preschoolGroupNameRu, kg: results.applicationShortDto.preschoolGroupNameKg}) }}</b></div>
          <OrganizationGroups :groups="organizationGroups"
                              :group-id="results.applicationShortDto.organizationGroupId"/>
        </div>

      </div>

      <ResultTable :current-application="results.applicationShortDto"
                   :applications="results.applicationsListInOrder"/>
    </div>
    <div v-if="results.statusCode === 'DROPPED' && results.applicationShortDto">
      <div class="check-results-block-container">
        <div class="check-results-username"><h3>{{ results.applicationShortDto.childFio }}</h3></div>
        <div class="check-results-main-info">
          <i class="bi-x-circle"></i>
          <h5>{{ $lang.app.selected_preschool }}</h5>
          <h5>{{ $lang.$translate({ru: results.applicationShortDto.organizationNameRu, kg: results.applicationShortDto.organizationNameKg}) }}</h5>
          <h5>{{ $lang.app.selected_preschool_address }}</h5>
          <h5 class="margin-bottom-35">{{ $lang.$translate({ru: results.applicationShortDto.organizationAddressRu, kg: results.applicationShortDto.organizationAddressKg}) }}</h5>
          <h4>{{ $lang.app.application_dropped }}</h4>
        </div>
        <div class="extra-info">
          <div class="extra-info-content">
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_age }}</div>
              <div class="extra-info-item-value">{{ results.applicationShortDto.age }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.group }}</div>
              <div class="extra-info-item-value">
                {{ $lang.$translate({ru: results.applicationShortDto.preschoolGroupNameRu, kg: results.applicationShortDto.preschoolGroupNameKg}) }}
              </div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_birth_year }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.birthdate).format('YYYY') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.application_created_date }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.createdAt).format('DD.MM.YYYY HH:mm') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.privilege }}</div>
              <div class="extra-info-item-value">{{ applicationPrivilege }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="results.statusCode === 'WAITING' && results.applicationShortDto">
      <div class="check-results-block-container">
        <div class="check-results-username"><h3>{{ results.applicationShortDto.childFio }}</h3></div>
        <div class="check-results-main-info check-results-main-info-pending">
          <i class="bi-hourglass"></i>
          <h5>{{ $lang.app.selected_preschool }}</h5>
          <h5>{{ $lang.$translate({ru: results.applicationShortDto.organizationNameRu, kg: results.applicationShortDto.organizationNameKg}) }}</h5>
          <h5>{{ $lang.app.selected_preschool_address }}</h5>
          <h5 class="margin-bottom-35">{{ $lang.$translate({ru: results.applicationShortDto.organizationAddressRu, kg: results.applicationShortDto.organizationAddressKg}) }}</h5>
          <h4>{{ $lang.app.application_waiting }}</h4>
          <h5 v-if="results.applicationsQueueDto">{{ $lang.app.your_queue.replace(':queue', results.applicationsQueueDto.row) }}</h5>
        </div>
        <div class="extra-info">
          <div class="extra-info-content">
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_age }}</div>
              <div class="extra-info-item-value">{{ results.applicationShortDto.age }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.group }}</div>
              <div class="extra-info-item-value">
                {{ $lang.$translate({ru: results.applicationShortDto.preschoolGroupNameRu, kg: results.applicationShortDto.preschoolGroupNameKg}) }}
              </div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_birth_year }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.birthdate).format('YYYY') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.application_created_date }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.statusRegistrationDate).format('DD.MM.YYYY HH:mm') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.privilege }}</div>
              <div class="extra-info-item-value">{{ applicationPrivilege }}</div>
            </div>
          </div>
        </div>

        <div v-if="results.applicationShortDto.organizationGroupId && organizationGroups" class="organization-quotas-table-wrapper">
          <div><b>{{ $lang.app.your_group }}: {{ $lang.$translate({ru: results.applicationShortDto.preschoolGroupNameRu, kg: results.applicationShortDto.preschoolGroupNameKg}) }}</b></div>
          <OrganizationGroups :groups="organizationGroups"
                              :group-id="results.applicationShortDto.organizationGroupId"/>
        </div>

        <div v-if="results.applicationsQueueDto" class="application-queue">
          {{ $lang.app.status_queue.replace(':queue', results.applicationsQueueDto.row).replace(':total', results.applicationsQueueDto.total) }}
        </div>
      </div>

      <ResultTable :current-application="results.applicationShortDto"
                   :applications="results.applicationsListInOrder"/>
    </div>
    <div v-if="results.statusCode === 'WAITING_ENROLLING' && results.applicationShortDto">
      <div class="check-results-block-container">
        <div class="check-results-username"><h3>{{ results.applicationShortDto.childFio }}</h3></div>
        <div class="check-results-main-info check-results-main-info-pending">
          <i class="bi-hourglass"></i>
          <h5>{{ $lang.app.selected_preschool }}</h5>
          <h5>{{ $lang.$translate({ru: results.applicationShortDto.organizationNameRu, kg: results.applicationShortDto.organizationNameKg}) }}</h5>
          <h5>{{ $lang.app.selected_preschool_address }}</h5>
          <h5 class="margin-bottom-35">{{ $lang.$translate({ru: results.applicationShortDto.organizationAddressRu, kg: results.applicationShortDto.organizationAddressKg}) }}</h5>
          <h4>{{ $lang.app.application_waiting_enrolling }}</h4>
        </div>
        <div class="extra-info">
          <div class="extra-info-content">
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_age }}</div>
              <div class="extra-info-item-value">{{ results.applicationShortDto.age }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.group }}</div>
              <div class="extra-info-item-value">
                {{ $lang.$translate({ru: results.applicationShortDto.preschoolGroupNameRu, kg: results.applicationShortDto.preschoolGroupNameKg}) }}
              </div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_birth_year }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.birthdate).format('YYYY') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.application_created_date }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.createdAt).format('DD.MM.YYYY HH:mm') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.privilege }}</div>
              <div class="extra-info-item-value">{{ applicationPrivilege }}</div>
            </div>
          </div>
        </div>

        <div v-if="results.applicationShortDto.organizationGroupId && organizationGroups" class="organization-quotas-table-wrapper">
          <div><b>{{ $lang.app.your_group }}: {{ $lang.$translate({ru: results.applicationShortDto.preschoolGroupNameRu, kg: results.applicationShortDto.preschoolGroupNameKg}) }}</b></div>
          <OrganizationGroups :groups="organizationGroups"
                              :group-id="results.applicationShortDto.organizationGroupId"/>
        </div>

      </div>
    </div>
    <div v-if="results.statusCode === 'ENROLLED' && results.applicationShortDto">
      <div class="check-results-block-container">
        <div class="check-results-username"><h3>{{ results.applicationShortDto.childFio }}</h3></div>
        <div class="check-results-main-info check-results-main-info-enroll">
          <i class="bi-check-all"></i>
          <h5>{{ $lang.app.selected_preschool }}</h5>
          <h5>{{ $lang.$translate({ru: results.applicationShortDto.organizationNameRu, kg: results.applicationShortDto.organizationNameKg}) }}</h5>
          <h5>{{ $lang.app.selected_preschool_address }}</h5>
          <h5 class="margin-bottom-35">{{ $lang.$translate({ru: results.applicationShortDto.organizationAddressRu, kg: results.applicationShortDto.organizationAddressKg}) }}</h5>
          <h4>
            {{ $lang.app.status_enroll }}
          </h4>
          <span v-if="results.applicationShortDto.enrolledDate">{{ $moment(results.applicationShortDto.enrolledDate).format('DD.MM.YYYY HH:mm') }}</span>
        </div>
        <div class="extra-info">
          <div class="extra-info-content">
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_age }}</div>
              <div class="extra-info-item-value">{{ results.applicationShortDto.age }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.group }}</div>
              <div class="extra-info-item-value">
                {{ $lang.$translate({ru: results.applicationShortDto.preschoolGroupNameRu, kg: results.applicationShortDto.preschoolGroupNameKg}) }}
              </div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.child_birth_year }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.birthdate).format('YYYY') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.application_created_date }}</div>
              <div class="extra-info-item-value">{{ $moment(results.applicationShortDto.createdAt).format('DD.MM.YYYY HH:mm') }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.privilege }}</div>
              <div class="extra-info-item-value">{{ applicationPrivilege }}</div>
            </div>
            <div class="extra-info-item">
              <div class="extra-info-item-label">{{ $lang.app.enroll_date }}</div>
              <div v-if="results.applicationShortDto.enrolledDate" class="extra-info-item-value">{{ $moment(results.applicationShortDto.enrolledDate).format('DD.MM.YYYY HH:mm') }}</div>
            </div>
          </div>
        </div>

        <div v-if="results.applicationShortDto.organizationGroupId && organizationGroups" class="organization-quotas-table-wrapper">
          <div><b>{{ $lang.app.your_group }}: {{ $lang.$translate({ru: results.applicationShortDto.preschoolGroupNameRu, kg: results.applicationShortDto.preschoolGroupNameKg}) }}</b></div>
          <OrganizationGroups :groups="organizationGroups"
                              :group-id="results.applicationShortDto.organizationGroupId"/>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ResultTable from './ResultTable'
import OrganizationGroups from "./OrganizationGroups";
export default {
  props: {
    results: Object
  },
  components: {
    OrganizationGroups,
    ResultTable
  },
  data() {
    return {
      organizationGroups: null,
    }
  },
  computed: {
    applicationPrivilege() {
        if (this.results.applicationShortDto.childPrivilegeTypeId > 1) {
          return this.$lang.$translate({
            ru: this.results.applicationShortDto.childPrivilegeTypeRu,
            kg: this.results.applicationShortDto.childPrivilegeTypeKg
          })
        }

      return this.$lang.$translate({
        ru: this.results.applicationShortDto.applicantPrivilegeTypeRu,
        kg: this.results.applicationShortDto.applicantPrivilegeTypeKg
      })
    }
  },
  created() {
    this.getOrganizationGroups()
  },
  methods: {
    getOrganizationGroups() {
      this.organizationGroups = null
      if(['REJECTED', 'WAITING', 'WAITING_ENROLLING', 'ENROLLED'].includes(this.results.statusCode)) {
        this.getOrganizationQuotaQuantities(this.results.applicationShortDto.organizationId)
      }
    },
    async getOrganizationQuotaQuantities(organizationId) {

      await this.$recaptchaLoaded()
      let recaptchaToken = await this.$recaptcha('login')

      this.$axios.post(`/statistics/organization/${organizationId}`, {
        type: 'PRESCHOOL',
        recaptchaToken: recaptchaToken,
      }).then(({data}) => {
        this.organizationGroups = data.groupQuotaDtos
      })
    }
  }
}
</script>
<style>
.margin-bottom-35 {
  margin-bottom: 35px;
}
.application-queue {
  padding: 0 16.6666%;
  font-weight: bold;
  margin-bottom: 10px;
}
.check-results-username {
  padding: 50px 0;
}
.check-results-username h3 {
  margin: 0;
  text-align: center;
  font-weight: bold;
  font-size: 48px;
}
.check-results-main-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  background: indianred;
}
.check-results-main-info h5 {
  max-width: 640px;
  text-align: center;
}
.extra-info {
  padding: 0 16.6666%;
  background: rgba(200,127,80,0.2);
  margin-bottom: 30px;
}
.extra-info-content {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
}
.extra-info-item {
  width: 50%;
  display: flex;
  font-size: 16px;
  margin: 5px 0;
}
.extra-info-item-label {
  width: 35%;
  color: gray;
  flex-shrink: 0;
}
.extra-info-item-value {
  font-weight: bold;
}
.check-results-main-info-enroll {
  background: #74b65f;
}
.check-results-main-info-pending {
  background: darkorange;
}
.check-results-main-info i {
  color: #fff;
  font-size: 48px;
  display: inline-block;
  margin-bottom: 20px;
}
.check-results-main-info h4 {
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  margin: 0 0 15px 0;
  font-weight: bold;
  text-align: center;
}
.check-results-main-info span {
  color: #fff;
  font-size: 18px;
}
.check-results-main-info h5 {
  color: #fff;
  font-weight: bold;
  font-size: 22px;
}
.check-results-notice {
  padding: 35px 16.6666% 40px;
  display: flex;
}
.check-results-notice-icon {
  color: red;
  font-size: 48px;
  display: inline-block;
  margin-right: 30px;
}
.check-results-notice h4 {
  margin: 15px 0;
  text-transform: uppercase;
  font-weight: bold;
}
.check-results-btn {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
}
.check-results-btn a {
  width: 16.6666%;
  display: inline-block;
  height: 55px;
  line-height: 55px;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  padding: 0;
  transition: 0.5s;
  text-decoration: none;
  font-size: 18px;
  background: linear-gradient(to bottom, #efefef 0%, #e2e2e2 100%);
  border: 1px solid #d0d0d0;
  color: #4c9ac7;
}
.organization-quotas-table-wrapper {
  padding: 0 16.6666%;
}
@media screen and (max-width: 1600px) {
  .check-results-username {
    padding: 40px 0;
  }
  .check-results-btn a {
    width: 25%;
  }
}
@media screen and (max-width: 1200px) {
  .check-results-username {
    padding: 30px 0;
  }
  .check-results-username h3 {
    font-size: 24px;
  }
  .check-results-main-info i {
    margin-bottom: 15px;
  }
  .check-results-main-info h4 {
    font-size: 18px;
  }
  .check-results-main-info span {
    font-size: 16px;
  }
  .check-results-notice-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  .check-results-notice h4 {
    margin: 0 0 15px;
    font-size: 16px;
  }
  .check-results-notice p {
    font-size: 16px;
  }
  .check-results-btn a {
    width: 33.3333%;
  }
}
@media screen and (max-width: 767px) {
  .check-results-username {
    padding: 25px 0;
  }
  .check-results-main-info {
    padding: 15px 0 25px;
  }
  .extra-info {
    padding: 0;
  }
  .application-queue {
    padding: 0;
  }
  .check-results-main-info i {
    margin-bottom: 10px;
  }
  .check-results-notice {
    padding: 25px 15px 30px;
  }
  .check-results-btn {
    padding: 30px 15px;
  }
  .check-results-btn a {
    width: 100%;
  }
  .extra-info-item {
    width: 100%;
    padding: 0 10px;
  }
  .extra-info-item-label {
    width: 40%;
  }
  .organization-quotas-table-wrapper {
    padding: 0;
  }
}
@media screen and (max-width: 479px) {
  .check-results-username {
    padding: 20px 0;
  }
  .check-results-main-info i {
    font-size: 32px;
  }
}
</style>