<template>
  <div class="check-results-table">
    <table v-if="applications">
      <thead>
      <tr>
        <th class="padding-left"></th>
        <th class="title-number"><h5>№</h5></th>
        <th><h5>{{ $lang.app.application_number }}</h5></th>
        <th><h5>{{ $lang.app.application_created_date }}</h5></th>
        <th><h5>{{ $lang.app.fullname }}</h5></th>
        <th><h5>{{ $lang.app.child_birth_year }}</h5></th>
        <th><h5>{{ $lang.app.group }}</h5></th>
        <th><h5>{{ $lang.app.privilege }}</h5></th>
        <th><h5>{{ $lang.app.status }}</h5></th>
        <th class="padding-right"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(application, index) in applications" :key="index"
          :class="{'active active-enroll': application.status === 'ENROLLED' && application.number === currentApplication.number,
              'active active-pending': ['WAITING', 'WAITING_ENROLLING'].includes(application.status) && application.number === currentApplication.number,
              'active active-rejected': application.status === 'REJECTED' && application.number === currentApplication.number,
              'active active-archived': application.status === 'ARCHIVED' && application.number === currentApplication.number }">
        <td class="padding-left"></td>
        <td class="body-number" :class="{'active active-enroll': application.status === 'ENROLLED' && application.number === currentApplication.number,
              'active active-pending': ['WAITING', 'WAITING_ENROLLING'].includes(application.status) && application.number === currentApplication.number,
              'active active-rejected': application.status === 'REJECTED' && application.number === currentApplication.number,
              'active active-archived': application.status === 'ARCHIVED' && application.number === currentApplication.number }">
          <span>{{ application.row }}</span>
        </td>
        <td><span>{{ application.number }}</span></td>
        <td><span>{{ $moment(application.createdAt).format('DD.MM.YYYY HH:mm') }}</span></td>
        <td><span>{{ application.childFio }}</span></td>
        <td><span>{{ application.birthdate }}</span></td>
        <td><span>{{ applicationGroup(application) }}</span></td>
        <td><span>{{ $lang.$translate({ru: application.childPrivilegeTypeRu, kg: application.childPrivilegeTypeKg}) }}</span></td>
        <td>
          <div class="check-result-status">
            <i v-if="application.status === 'ENROLLED'"
               :title="$lang.app.status_enroll"
               class="bi-check-all check-result-status-enroll"></i>
            <i v-if="['WAITING', 'WAITING_ENROLLING'].includes(application.status)"
               :title="$lang.app.status_waiting"
               class="bi-hourglass check-result-status-pending"></i>
            <i v-if="application.status === 'REJECTED'"
               :title="$lang.app.status_rejected"
               class="bi-x-circle check-result-status-rejected"></i>
            <i v-if="application.status === 'ARCHIVED'"
               :title="$lang.app.status_archived"
               class="bi-archive check-result-status-archived"></i>
          </div>
        </td>
        <td class="padding-right"></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ['currentApplication', 'applications'],
  methods: {
    applicationGroup(application) {
      return (application.organizationGroupName ? application.organizationGroupName + ' - ' : '') + this.$lang.$translate({
        ru: application.preschoolGroupNameRu ? application.preschoolGroupNameRu : '',
        kg: application.preschoolGroupNameKg ? application.preschoolGroupNameKg : ''})
    }
  }
}
</script>
<style>
.check-results-table {
  padding: 0 0 40px;
}
.check-results-table table {
  width: 100%;
  border-collapse: collapse;
}
.check-results-table thead tr {
  background: #cfe6e3;
}
.padding-left, .padding-right {
  width: 16.6666%;
}
.check-results-table thead tr .title-number {
  width: 50px;
  background: #93cac4;
}
.check-results-table thead tr h5 {
  margin: 10px 7px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}
.check-results-table tbody td {
  text-align: center;
}
.check-results-table tbody .body-number {
  background: #cfe6e3;
}
.check-results-table tbody .body-number span {
  color: #1f1f1f;
}
.check-results-table tbody td span {
  color: #888;
  font-size: 16px;
  margin: 7px;
  display: inline-block;
}
.check-result-status-rejected {
  color: indianred;
}
.check-result-status i {
  font-size: 24px;
}
.check-result-status-enroll {
  color: #74b65f;
}
.check-result-status-pending {
  color: darkorange;
}
.check-result-status-archived {
  color: skyblue;
}
.check-results-table tbody .active {
  background: indianred;
}
.check-results-table tbody .active-enroll {
  background: #74b65f;
}
.check-results-table tbody .active-pending {
  background: darkorange;
}
.check-results-table tbody .active-archived {
  background: skyblue;
}
.check-results-table tbody .active span {
  color: #fff;
}
.check-results-table tbody .active i {
  color: #fff;
}
@media screen and (max-width: 1200px) {
  .padding-left, .padding-right {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .check-results-table {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 479px) {
  .check-results-table thead tr h5 {
    font-size: 14px;
  }
  .check-results-table tbody td span {
    font-size: 14px;
  }
}
</style>