<template>
  <div>
    <div class="organization-guotas-table">
      <table>
        <thead>
        <tr>
          <th class="title-number"><h5>№</h5></th>
          <th><h5>{{ $lang.app.group }}</h5></th>
          <th><h5>{{ $lang.app.education_language }}</h5></th>
          <th><h5>{{ $lang.app.quotas }}</h5></th>
          <th><h5>{{ $lang.app.enrolled }}</h5></th>
          <th><h5 v-html="$lang.app.available_quotas"></h5></th>
          <th><h5>{{ $lang.app.waiting_for_enrolling }}</h5></th>
          <th><h5>{{ $lang.app.waiting_for_enroll }}</h5></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(group, index) in groups" :key="index" :class="{'active-group': group.groupId === groupId}">
          <td class="body-number"><div>{{ index + 1 }}</div></td>
          <td><div>{{ $lang.$translate({ru: group.groupNameRu, kg: group.groupNameKg}) }}</div></td>
          <td><div>{{ $lang.$translate({ru: group.languageRu, kg: group.languageKg}) }}</div></td>
          <td>
            <div>
              <span>{{ group.totalQuota }}</span><br>
            </div>
          </td>
          <td>
            <div>
              <span>{{ group.totalEnrolled }}</span><br>
            </div>
          </td>
          <td>
            <div>
              <span>{{ group.totalFree }}</span><br>
            </div>
          </td>
          <td>
            <div>
              <span>{{ group.totalWaitingEnrolling }}</span><br>
            </div>
          </td>
          <td>
            <div>
              <span>{{ group.totalWaiting }}</span><br>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    groups: Array,
    groupId: {
      type: Number,
      default: null,
    }
  }
}
</script>
<style>
.organization-guotas-table {
  padding: 10px 0 40px;
}
.organization-guotas-table .active-group {
  background: bisque;
}
.organization-guotas-table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #93cac4;
}
.organization-guotas-table table th, .organization-guotas-table table td {
  padding: 0 5px;
}
.organization-guotas-table thead tr {
  background: #cfe6e3;
}
.organization-guotas-table thead tr .title-number {
  width: 50px;
  background: #93cac4;
}
.organization-guotas-table thead tr h5 {
  margin: 10px 0;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}
.organization-guotas-table tbody td {
  text-align: center;
  border-bottom: 1px solid #93cac4;
}
.organization-guotas-table tbody .body-number {
  background: #cfe6e3;
}
.organization-guotas-table tbody .body-number {
  color: #1f1f1f;
}
.organization-guotas-table tbody td div {
  color: #1f1f1f;
  font-size: 16px;
  margin: 7px 0;
  display: inline-block;
}
/*.organization-guotas-table tbody td div span {*/
/*  white-space: nowrap;*/
/*}*/
@media screen and (max-width: 1200px) {
  .organization-guotas-table {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 479px) {
  .organization-guotas-table thead tr h5 {
    font-size: 14px;
  }
  .organization-guotas-table tbody td div {
    font-size: 14px;
  }
}
</style>